import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const ReplyBasedOrUrlBased = ({ location }) => {
  const title = "Does Instant Win support reply or URL type?"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="Both reply and URL types are supported. We recommend the method of notifying lottery results via direct message. The message includes a URL, and the results can be confirmed by navigating to that URL."
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              Are the campaigns held in PARKLoT reply or URL type?
            </h1>
            <p className="qa-page__answer">URL type.</p>
            <p className="qa-page__text">
              Lottery results will be notified via direct message. The message
              contains a URL, and you can check the results by viewing the URL
              in your browser.
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">
                  The difference between URL type and reply type
                </h2>

                <p className="qa-page__text">
                  There are several notification methods for lottery results.
                  The characteristics of each are described below.
                </p>

                <h3 className="qa-page__h3">What is a URL type?</h3>
                <p className="qa-page__text">
                  This is a method of participation in which you will be
                  notified of the results of the campaign drawing by URL.
                  <br />
                  Participants click on the URL and check the lottery results at
                  the destination website.
                </p>

                <h3 className="qa-page__h3">What is a reply type?</h3>
                <p className="qa-page__text">
                  The method is that you will be notified of the results of the
                  campaign drawing by reply.
                  <br />
                  Users who are not participating in the campaign can also view
                  the results of the campaign drawing.
                </p>
              </div>

              <div>
                <h2 className="qa-page__h2">
                  Why PARKLoT uses URL type (send URL by DM)?
                </h2>

                <p className="qa-page__text">
                  This system is adopted to "allow users to receive prizes
                  without the need to log in.
                  <br />
                  *Please note that there are some services that require login
                  for the same method.
                  <br />
                  *If you have reached the limit for sending direct messages,
                  you will not receive notification by message, in which case
                  you will need to log in to check the results.
                </p>

                <p className="qa-page__text">
                  In case of reply type, "login is required to check the lottery
                  results".
                  <br />
                  Due to Twitter specifications, replies can be viewed by all
                  participants, so you must log in with the Twitter account you
                  applied for in order to verify your account.
                </p>

                <p className="qa-page__text">
                  It is possible to customize the result notification method to
                  a reply type, but the URL to receive the gift will be known to
                  users other than the winner. Therefore, Twitter login is
                  required for account verification both when confirming results
                  and receiving gifts.
                </p>

                <p className="qa-page__text">
                  We have confirmed that some accounts do not notify users in
                  the case of replies, so we basically deprecate them.
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default ReplyBasedOrUrlBased
